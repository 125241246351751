import React from 'react';
import { X as XIcon, LinkedIn, Mail } from '@mui/icons-material';

const Section = ({ title, children }) => (
  <section className="mb-8">
    <h2 className="text-2xl font-mono border-b-2 border-black pb-2 mb-4">{title}</h2>
    {children}
  </section>
);

const Project = ({ title, link }) => (
  <div className="mb-2">
    <a href={link} target="_blank" rel="noopener noreferrer" className="font-mono text-black hover:underline">
      &gt; {title}
    </a>
  </div>
);

const ContactLink = ({ href, Icon, label }) => (
  <a href={href} target="_blank" rel="noopener noreferrer" className="flex items-center font-mono text-black hover:underline mb-2">
    <Icon style={{ fontSize: 20, marginRight: 8 }} />
    {label}
  </a>
);

const Portfolio = () => (
  <div className="min-h-screen bg-white text-black p-8 flex justify-center">
    <div className="max-w-2xl w-full">
      <h1 className="text-4xl font-mono mb-8 text-center">Reid McCrabb</h1>
      
      <Section title="ABOUT ME">
        <p className="font-mono">
          Building in AI | Austin, TX
        </p>
      </Section>
      
      <Section title="BUILDING">
        <Project 
          title="Linkt AI" 
          link="https://www.linkt.ai/"
        />
        <Project 
          title="DocPrompt" 
          link="https://www.docprompt.io/"
        />
        <Project 
          title="Hack AI" 
          link="https://www.hackai.live/"
        />
      </Section>

      <Section title="GET IN TOUCH">
        <ContactLink 
          href="https://x.com/reidmccrabb" 
          Icon={XIcon}
          label="@reidmccrabb"
        />
        <ContactLink 
          href="https://www.linkedin.com/in/reidmccrabb" 
          Icon={LinkedIn}
          label="@reidmccrabb"
        />
        <ContactLink 
          href="mailto:reid@linkt.ai" 
          Icon={Mail}
          label="reid@linkt.ai"
        />
      </Section>
    </div>
  </div>
);

export default Portfolio;